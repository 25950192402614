/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

import whiteLogo from 'images/bold-n-brave-white.svg';
import blackLogo from 'images/bold-n-brave-black.svg';
import pinkLogo from 'images/bold-n-brave-pink.svg';
import closeIcon from 'images/close.svg';

import bbSmall from 'images/bb-og.png'
import heroImg1 from 'images/a1.png';
import heroImg2 from 'images/a2.png';
import heroImg3 from 'images/a3.jpg';
import moutain2 from 'images/c.png';
import moutain3 from 'images/d.png';
import video from 'videos/mountain.mp4';
import MC from 'images/Magnus_Christiansson.png';
import JR from 'images/Johan_Rosengren.png';
import PF from 'images/Patrick_Fogwall.png';
import AH from 'images/Andreas_Hellberg.png';
import JN from 'images/Justin_Nel.png';
import MR from 'images/Markus_Rytterkull.png';
import DV from 'images/Damjan_Velickovski.png';
import DS from "images/David_Soderlund.png"

import graph from 'images/graph.png';
import chart from 'images/chart.png';

import ANONYMOUS from 'images/anonymous.png';


import casesKI from 'images/kjell_intro.png';
import casesKL from 'images/kjell_logo.png';
import casesKMK from 'images/kjell_martin-k.jpg';
import casesKS from 'images/kjell_screens.png';
import caseKOg from 'images/kjell_og.jpg'
import innovate from 'images/innovate.svg';
import elevate from 'images/elevate.svg';
import spotlight from 'images/spotlight.svg';
import favIcon from 'images/favicon.ico';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCNzzStToeC70Vb2kHxZAvQbY2KRx604uc",
    authDomain: "bold-n-brave-publ.firebaseapp.com",
    databaseURL: "https://bold-n-brave-publ.firebaseio.com",
    projectId: "bold-n-brave-publ",
    storageBucket: "bold-n-brave-publ.appspot.com",
    messagingSenderId: "477676607133",
    appId: "1:477676607133:web:76736be602c2248ee9a674"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
